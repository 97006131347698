<template>
  <div ref="table" :class="{'watchlist-main': true, 'hide-scrollable-effect': hideEffect}">
    <div ref="wrapper" :class="{'watchlist-main__wrapper': true, 'no-chart': !withChart}">
      <div :class="{'watchlist-main__header watchlist-legends': true, 'no-chart': !withChart}">
        <div class="watchlist-legend watchlist-legends__order sortable" @click="changeSort('cmc_rank')">
          <div class="watchlist-legends__name">
            #
          </div>
          <div v-if="sort && filter.orderField === 'cmc_rank'" class="watchlist-legends__icon">
            <i v-if="filter.order.toLowerCase() === 'desc'" class="bx bxs-down-arrow"></i>
            <i v-if="filter.order.toLowerCase() === 'asc'" class="bx bxs-up-arrow"></i>
          </div>
        </div>
        <div class="watchlist-legend watchlist-legends__title sortable" @click="changeSort('title')">
          <div class="watchlist-legends__name">
            {{ $t('filter.title') }}
          </div>
          <div v-if="sort && filter.orderField === 'title'" class="watchlist-legends__icon">
            <i v-if="filter.order.toLowerCase() === 'desc'" class="bx bxs-down-arrow"></i>
            <i v-if="filter.order.toLowerCase() === 'asc'" class="bx bxs-up-arrow"></i>
          </div>
        </div>
        <div class="watchlist-legend watchlist-legends__price sortable" @click="changeSort('price')">
          <div class="watchlist-legends__name">
            {{ $t('filter.price') }}
          </div>
          <div v-if="sort && filter.orderField === 'price'" class="watchlist-legends__icon">
            <i v-if="filter.order.toLowerCase() === 'desc'" class="bx bxs-down-arrow"></i>
            <i v-if="filter.order.toLowerCase() === 'asc'" class="bx bxs-up-arrow"></i>
          </div>
        </div>
        <div class="watchlist-legend watchlist-legends__day-procent sortable" @click="changeSort('24h_procents')">
          <div class="watchlist-legends__name">
            24{{ $t('common.h') }} %
          </div>
          <div v-if="sort && filter.orderField === '24h_procents'" class="watchlist-legends__icon">
            <i v-if="filter.order.toLowerCase() === 'desc'" class="bx bxs-down-arrow"></i>
            <i v-if="filter.order.toLowerCase() === 'asc'" class="bx bxs-up-arrow"></i>
          </div>
        </div>
        <div class="watchlist-legend watchlist-legends__week-procent sortable" @click="changeSort('7d_procents')">
          <div class="watchlist-legends__name">
            7{{ $t('common.d') }} %
          </div>
          <div v-if="sort && filter.orderField === '7d_procents'" class="watchlist-legends__icon">
            <i v-if="filter.order.toLowerCase() === 'desc'" class="bx bxs-down-arrow"></i>
            <i v-if="filter.order.toLowerCase() === 'asc'" class="bx bxs-up-arrow"></i>
          </div>
        </div>
        <div class="watchlist-legend watchlist-legends__market-cap">
          <div class="watchlist-legends__name">
            {{ $t('filter.market_cap') }}
            <span v-tippy="{...tippyOptions, 'content': getContentById('marketcap_tooltip')}" class="tip">
              <i class="bx bxs-info-circle"></i>
            </span>
          </div>
        </div>
        <div class="watchlist-legend watchlist-legends__volume sortable" @click="changeSort('volume')">
          <div class="watchlist-legends__name">
            {{ $t('filter.volume') }}(24{{ $t('common.h') }})
            <span v-tippy="{...tippyOptions, 'content': getContentById('volume_tooltip')}" class="tip">
              <i class="bx bxs-info-circle"></i>
            </span>
          </div>
          <div v-if="sort && filter.orderField === 'volume'" class="watchlist-legends__icon">
            <i v-if="filter.order.toLowerCase() === 'desc'" class="bx bxs-down-arrow"></i>
            <i v-if="filter.order.toLowerCase() === 'asc'" class="bx bxs-up-arrow"></i>
          </div>
        </div>
        <div class="watchlist-legend watchlist-legends__supply sortable" @click="changeSort('supply')">
          <div class="watchlist-legends__name">
            {{ $t('filter.supply') }}
            <span v-tippy="{...tippyOptions, 'content': getContentById('supply_tooltip')}" class="tip">
              <i class="bx bxs-info-circle"></i>
            </span>
          </div>
          <div v-if="sort && filter.orderField === 'supply'" class="watchlist-legends__icon">
            <i v-if="filter.order.toLowerCase() === 'desc'" class="bx bxs-down-arrow"></i>
            <i v-if="filter.order.toLowerCase() === 'asc'" class="bx bxs-up-arrow"></i>
          </div>
        </div>
        <div v-if="withChart" class="watchlist-legend watchlist-legends__last-days">
          <div class="watchlist-legends__name">
            {{ $t('filter.last_2_weeks') }}
          </div>
        </div>
        <div class="watchlist-legend watchlist-legends__settings"></div>
      </div>
      <div v-if="!loading && projects.length > 0" :class="{'watchlist-main__list': true}">
        <div v-for="(project, ind) in projects" :key="ind" :class="{'watchlist-product': true, 'no-chart': !withChart}">
          <div :class="{'watchlist-product__wrapper': true, 'no-chart': !withChart}">
            <div class="watchlist-product__part watchlist-product__part_actions">
              <div v-if="user !== null && watchlistChanging !== project.id" :class="{'watchlist-product__favorite watchlist-favorite': true, '_favorite': insideWatchlist(project.id)}" :text-tooltip="insideWatchlist(project.id) ? $t('project.remove') : $t('project.add')" flow="right" @click="toggleWatchlist(project.id)">
                <i :class="{'bx bx-star watchlist-favorite__stroke': true, 'bx bxs-star watchlist-favorite__filled': insideWatchlist(project.id)}"></i>
              </div>
              <span v-else-if="user !== null && watchlistChanging === project.id" class="watchlist-product__favorite watchlist-favorite">
                <i class="bx bx-loader-alt loading watchlist-favorite__stroke"></i>
              </span>
              <nuxt-link v-else :to="localePath('/login')" class="watchlist-product__favorite watchlist-favorite" :text-tooltip="$t('common.login_first')" flow="right">
                <i class="bx bx-star watchlist-favorite__stroke"></i>
              </nuxt-link>
              <span class="watchlist-product__num">
                {{ (filter.step * (filter.page - 1) + ind + 1) }}
              </span>
            </div>
            <div class="watchlist-product__part watchlist-product-name">
              <div class="watchlist-product-name__box">
                <nuxt-link :to="$getProjectLink(project.type, project.slug)" class="watchlist-product-name__icon">
                  <nuxt-img
                    :src="$getImage(project.project_image, 'projects')"
                    :alt="project.title + ' ' + $t('common.at') + ' ' + $config.appName"
                    :title="project.title"
                    :quality="80"
                    format="webp"
                    loading="lazy"
                    @error="$event.target.src = $config.mediaUrl + 'img/projects/default.webp'"
                  />
                </nuxt-link>
                <nuxt-link :to="$getProjectLink(project.type, project.slug)" class="watchlist-product-name__name" :title="project.title">
                  <span class="watchlist-product-name__full-name">
                    <i v-if="project.premium !== null" class="bx bxs-zap premium_icon"></i>
                    {{ project.title }}
                  </span>
                  <span v-if="project.token !== undefined && project.token !== null && project.token.length > 0 && project.type !== 'exchange'">
                    / {{ project.token.toUpperCase() }}
                  </span>
                </nuxt-link>
              </div>
            </div>
            <div class="watchlist-product__part watchlist-product__price">
              <span v-if="project.price !== null && project.price >= 0.0001" class="watchlist-product__text">
                ${{ project.price > 1 ? $priceFormat(project.price, 2) : $formatCryptoAmount(project.price, 6) }}
              </span>
              <span v-else-if="project.price !== null" class="watchlist-product__text" :text-tooltip="'$' + $formatCryptoAmount(project.price)">
                ${{ generateCutPrice(project.price) }}
              </span>
              <span v-else class="watchlist-product__text">
                $...
              </span>
            </div>
            <div class="watchlist-product__part watchlist-product-rate">
              <div :class="{'watchlist-product-rate__num': true, 'plus': project.daily_percent > 0, 'minus': project.daily_percent < 0}">
                <i v-if="project.daily_percent > 0" class="bx bxs-up-arrow"></i>
                <i v-else-if="project.daily_percent < 0" class="bx bxs-down-arrow"></i>
                {{ project.daily_percent !== null ? $priceFormat(project.daily_percent, 2) + '%' : '--' }}
              </div>
            </div>
            <div class="watchlist-product__part watchlist-product-rate">
              <div :class="{'watchlist-product-rate__num': true, 'plus': project.weekly_percent > 0, 'minus': project.weekly_percent < 0}">
                <i v-if="project.weekly_percent > 0" class="bx bxs-up-arrow"></i>
                <i v-else-if="project.weekly_percent < 0" class="bx bxs-down-arrow"></i>
                {{ project.weekly_percent !== null ? $priceFormat(project.weekly_percent, 2) + '%' : '--' }}
              </div>
            </div>
            <div class="watchlist-product__part watchlist-product__market-cap">
              <span class="watchlist-product__text">
                {{ project.market_cap !== null ? '$' + $priceFormat(project.market_cap, 0, '.', ',') : '--' }}
              </span>
            </div>
            <div class="watchlist-product__part watchlist-product-volume">
              <span class="watchlist-product-volume__text">
                {{ project.daily !== null ? '$' + $priceFormat(project.daily, 0) : '--' }}
              </span>
            </div>
            <div class="watchlist-product__part watchlist-product-supply">
              <div class="watchlist-product__text">
                {{ project.circulating_supply !== null ? '$' + $priceFormat(project.circulating_supply, 0, '.', ',') : '--' }}
              </div>
            </div>
            <div v-if="withChart" class="watchlist-product__part watchlist-product__last-days">
              <div class="crypto-chart">
                <client-only>
                  <MiniLineChart :ref="'miniChart' + ind" :chart-data="generateChartData(ind, project.price_change)" :options="chartOptions" />
                </client-only>
              </div>
            </div>
            <div class="watchlist-product__part watchlist-product-settings">
              <nuxt-link :to="$getProjectLink(project.type, project.slug)" class="watchlist-product-more__button" :aria-label="$t('common.open_project_page')">
                <i class="bx bx-right-arrow-alt"></i>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="loading" class="watchlist-main__list">
        <div v-for="ind in 20" :key="ind" class="watchlist-product">
          <div class="watchlist-product__wrapper">
            <div class="watchlist-product__part watchlist-product__part_actions">
              <span class="watchlist-product__num">
                {{ (filter.step * (filter.page - 1) + ind) }}
              </span>
            </div>
            <div class="watchlist-product__part watchlist-product-name">
              <div class="watchlist-product-name__box">
                <Skeleton circle width="28px" height="28px" class="watchlist-product-name__icon" />
                <div class="watchlist-product-name__name">
                  <Skeleton width="50px" height="16px" class="watchlist-product-name__full-name" />
                  /
                  <Skeleton width="30px" height="16px" />
                </div>
              </div>
            </div>
            <div class="watchlist-product__part watchlist-product__price">
              <Skeleton width="70px" height="15px" class="watchlist-product__text" />
            </div>
            <div class="watchlist-product__part watchlist-product-rate">
              <Skeleton width="40px" height="15px" class="watchlist-product__text" />
            </div>
            <div class="watchlist-product__part watchlist-product-rate">
              <Skeleton width="40px" height="15px" class="watchlist-product__text" />
            </div>
            <div class="watchlist-product__part watchlist-product__market-cap">
              <Skeleton width="100px" height="15px" class="watchlist-product__text" />
            </div>
            <div class="watchlist-product__part watchlist-product-volume">
              <Skeleton width="70px" height="15px" class="watchlist-product__text" />
            </div>
            <div class="watchlist-product__part watchlist-product-supply">
              <Skeleton width="70px" height="15px" class="watchlist-product__text" />
            </div>
            <div v-if="withChart" class="watchlist-product__part watchlist-product__last-days">
              <Skeleton width="160px" height="50px" class="watchlist-product__text" />
            </div>
            <div class="watchlist-product__part watchlist-product-settings">
              <div class="watchlist-product-more__button">
                <i class="bx bx-right-arrow-alt"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="watchlist-main__list not-found-area">
        <Empty />
        <div class="not-found-header">
          {{ $t('main.no_projects') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { Skeleton } from 'vue-loading-skeleton'

import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

import MiniLineChart from '~/components/charts/MiniLineChart'

export default {
  name: 'ProjectsTableComponent',
  components: {
    Empty: hydrateWhenVisible(() => import('~/components/misc/Empty')),
    MiniLineChart,
    Skeleton
  },
  props: {
    projects: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: () => true
    },
    withChart: {
      type: Boolean,
      default: () => true
    },
    sort: {
      type: Boolean,
      default: () => true
    },
    layout: {
      type: String,
      default: () => 'table'
    },
    filter: {
      type: Object,
      default: () => {
        return {
          order: 'ASC',
          orderField: 'cmc_rank'
        }
      }
    }
  },
  data() {
    return {
      hideEffect: false,
      chartOptions: {
        responsive: true,
        parsing: false,
        normalized: true,
        animation: false,
        spanGaps: true,
        maintainAspectRatio: false,
        hover: {
          mode: null
        },
        responsiveAnimationDuration: 0,
        scales: {
          xAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          yAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }]
        },
        datasets: {
          line: {
            pointRadius: 0
          }
        },
        elements: {
          point: {
            radius: 0
          }
        },
        plugins: {
          legend: false
        },
        tooltips: {
          enabled: false
        }
      }
    }
  },
  computed: {
    ...mapGetters(['tippyOptions']),
    ...mapGetters('auth', [
      'user',
      'watchlist',
      'watchlistChanging'
    ])
  },
  mounted() {
    if (this.$refs.wrapper !== undefined) {
      this.$refs.wrapper.addEventListener('scroll', this.handleTableScroll)
    }
    if (this.user !== null && this.watchlist.length === 0) {
      this.fetchWatchlist()
    }
  },
  beforeDestroy() {
    if (this.$refs.wrapper !== undefined) {
      this.$refs.wrapper.removeEventListener('scroll', this.handleTableScroll)
    }
  },
  methods: {
    ...mapActions('auth', [
      'fetchWatchlist',
      'addToWatchlist',
      'removeFromWatchlist'
    ]),
    insideWatchlist(id) {
      return this.watchlist.includes(id)
    },
    changeSort(newSort) {
      if (this.sort) {
        this.$emit('changeSort', newSort)
      }
    },
    generateChartData(ind, jsonData) {
      const dataset = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: 'transperant',
            borderColor: 'rgb(0, 183, 74)',
            borderWidth: 2,
            tension: 0.25,
            pointRadius: 0
          }
        ]
      }
      try {
        const dataObject = JSON.parse(jsonData)
        const points = dataObject !== null ? Object.values(dataObject) : []
        const keys = dataObject !== null ? Object.keys(dataObject) : []
        dataset.datasets[0].data = points
        dataset.labels = keys

        let color = '0, 183, 74'
        if (points[points.length - 2] !== undefined && points[points.length - 2] > points[points.length - 1]) {
          color = '219, 68, 55'
        }
        dataset.datasets[0].borderColor = 'rgb(' + color + ')'
        if (ind === this.projects.length - 1) {
          setTimeout(() => {
            this.generateGradients()
          }, 150)
        }
        return dataset
      } catch (err) {
        console.log(err)
        return dataset
      }
    },
    generateGradients() {
      for (let ind = 0; ind < this.projects.length; ++ind) {
        if (this.$refs['miniChart' + ind] !== undefined && this.$refs['miniChart' + ind][0] !== undefined) {
          const points = this.$refs['miniChart' + ind][0].chartData.datasets[0].data
          let color = '0, 183, 74'
          if (points[points.length - 2] !== undefined && points[points.length - 2] > points[points.length - 1]) {
            color = '219, 68, 55'
          }
          const ctx = this.$refs['miniChart' + ind][0].$el.querySelector('canvas').getContext('2d')
          const gradient = ctx.createLinearGradient(0, 0, 0, 40)
          gradient.addColorStop(0, 'rgba(' + color + ', 1)')
          gradient.addColorStop(1, 'rgba(' + color + ', 0)')
          this.$refs['miniChart' + ind][0].chartData.datasets[0].backgroundColor = gradient
          this.$refs['miniChart' + ind][0].chartData.datasets[0].borderColor = 'rgb(' + color + ')'
          this.$refs['miniChart' + ind][0].updateChartData()
        }
      }
    },
    getContentById(elem) {
      if (typeof document !== 'undefined') {
        return document.getElementById(elem) !== null ? document.getElementById(elem).innerHTML : '...'
      } else {
        return '...'
      }
    },
    generateCutPrice(price) {
      return price > 1
        ? this.$priceFormat(price, 2)
        : (price >= 0.0001 ? this.$priceFormat(price, 5) : this.$cutZeros(this.$formatCryptoAmount(price)))
    },
    handleTableScroll() {
      if (this.$refs.wrapper !== undefined) {
        const scrollLeft = this.$refs.wrapper.scrollLeft
        const maxScrollWidth = this.$refs.wrapper.offsetWidth
        const maxScrollAmt = this.$refs.wrapper.scrollWidth - maxScrollWidth

        if (scrollLeft >= maxScrollAmt - 50) {
          this.hideEffect = true
        } else {
          this.hideEffect = false
        }
      }
    },
    toggleWatchlist(id) {
      if (this.insideWatchlist(id)) {
        this.removeFromWatchlist({
          id
        })
      } else {
        this.addToWatchlist({
          id
        })
      }
    }
  }
}
</script>
