<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'MiniLineChartComponent',
  extends: Line,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
    this.$nuxt.$on('miniLineChartUpdate', this.updateChartData)
  },
  methods: {
    changeCartsColors() {
      if (this.chartData[this.chartData.length - 2] !== undefined && this.chartData[this.chartData.length - 2] > this.chartData[this.chartData.length - 1]) {
        this.chartData.datasets[0].borderColor = 'rgb(219, 68, 55)'
        this.chartData.datasets[0].backgroundColor = 'rgb(219, 68, 55)'
      } else {
        this.chartData.datasets[0].borderColor = 'rgb(0, 183, 74)'
        this.chartData.datasets[0].backgroundColor = 'rgb(0, 183, 74)'
      }
    },
    updateChartData() {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
