var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "table",
      class: {
        "watchlist-main": true,
        "hide-scrollable-effect": _vm.hideEffect,
      },
    },
    [
      _c(
        "div",
        {
          ref: "wrapper",
          class: {
            "watchlist-main__wrapper": true,
            "no-chart": !_vm.withChart,
          },
        },
        [
          _c(
            "div",
            {
              class: {
                "watchlist-main__header watchlist-legends": true,
                "no-chart": !_vm.withChart,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "watchlist-legend watchlist-legends__order sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("cmc_rank")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "watchlist-legends__name" }, [
                    _vm._v("\n          #\n        "),
                  ]),
                  _vm._v(" "),
                  _vm.sort && _vm.filter.orderField === "cmc_rank"
                    ? _c("div", { staticClass: "watchlist-legends__icon" }, [
                        _vm.filter.order.toLowerCase() === "desc"
                          ? _c("i", { staticClass: "bx bxs-down-arrow" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.filter.order.toLowerCase() === "asc"
                          ? _c("i", { staticClass: "bx bxs-up-arrow" })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "watchlist-legend watchlist-legends__title sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("title")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "watchlist-legends__name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("filter.title")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.sort && _vm.filter.orderField === "title"
                    ? _c("div", { staticClass: "watchlist-legends__icon" }, [
                        _vm.filter.order.toLowerCase() === "desc"
                          ? _c("i", { staticClass: "bx bxs-down-arrow" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.filter.order.toLowerCase() === "asc"
                          ? _c("i", { staticClass: "bx bxs-up-arrow" })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "watchlist-legend watchlist-legends__price sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("price")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "watchlist-legends__name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("filter.price")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.sort && _vm.filter.orderField === "price"
                    ? _c("div", { staticClass: "watchlist-legends__icon" }, [
                        _vm.filter.order.toLowerCase() === "desc"
                          ? _c("i", { staticClass: "bx bxs-down-arrow" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.filter.order.toLowerCase() === "asc"
                          ? _c("i", { staticClass: "bx bxs-up-arrow" })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "watchlist-legend watchlist-legends__day-procent sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("24h_procents")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "watchlist-legends__name" }, [
                    _vm._v(
                      "\n          24" +
                        _vm._s(_vm.$t("common.h")) +
                        " %\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.sort && _vm.filter.orderField === "24h_procents"
                    ? _c("div", { staticClass: "watchlist-legends__icon" }, [
                        _vm.filter.order.toLowerCase() === "desc"
                          ? _c("i", { staticClass: "bx bxs-down-arrow" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.filter.order.toLowerCase() === "asc"
                          ? _c("i", { staticClass: "bx bxs-up-arrow" })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "watchlist-legend watchlist-legends__week-procent sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("7d_procents")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "watchlist-legends__name" }, [
                    _vm._v(
                      "\n          7" +
                        _vm._s(_vm.$t("common.d")) +
                        " %\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.sort && _vm.filter.orderField === "7d_procents"
                    ? _c("div", { staticClass: "watchlist-legends__icon" }, [
                        _vm.filter.order.toLowerCase() === "desc"
                          ? _c("i", { staticClass: "bx bxs-down-arrow" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.filter.order.toLowerCase() === "asc"
                          ? _c("i", { staticClass: "bx bxs-up-arrow" })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "watchlist-legend watchlist-legends__market-cap",
                },
                [
                  _c("div", { staticClass: "watchlist-legends__name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("filter.market_cap")) +
                        "\n          "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: {
                              ..._vm.tippyOptions,
                              content: _vm.getContentById("marketcap_tooltip"),
                            },
                            expression:
                              "{...tippyOptions, 'content': getContentById('marketcap_tooltip')}",
                          },
                        ],
                        staticClass: "tip",
                      },
                      [_c("i", { staticClass: "bx bxs-info-circle" })]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "watchlist-legend watchlist-legends__volume sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("volume")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "watchlist-legends__name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("filter.volume")) +
                        "(24" +
                        _vm._s(_vm.$t("common.h")) +
                        ")\n          "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: {
                              ..._vm.tippyOptions,
                              content: _vm.getContentById("volume_tooltip"),
                            },
                            expression:
                              "{...tippyOptions, 'content': getContentById('volume_tooltip')}",
                          },
                        ],
                        staticClass: "tip",
                      },
                      [_c("i", { staticClass: "bx bxs-info-circle" })]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.sort && _vm.filter.orderField === "volume"
                    ? _c("div", { staticClass: "watchlist-legends__icon" }, [
                        _vm.filter.order.toLowerCase() === "desc"
                          ? _c("i", { staticClass: "bx bxs-down-arrow" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.filter.order.toLowerCase() === "asc"
                          ? _c("i", { staticClass: "bx bxs-up-arrow" })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "watchlist-legend watchlist-legends__supply sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("supply")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "watchlist-legends__name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("filter.supply")) +
                        "\n          "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: {
                              ..._vm.tippyOptions,
                              content: _vm.getContentById("supply_tooltip"),
                            },
                            expression:
                              "{...tippyOptions, 'content': getContentById('supply_tooltip')}",
                          },
                        ],
                        staticClass: "tip",
                      },
                      [_c("i", { staticClass: "bx bxs-info-circle" })]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.sort && _vm.filter.orderField === "supply"
                    ? _c("div", { staticClass: "watchlist-legends__icon" }, [
                        _vm.filter.order.toLowerCase() === "desc"
                          ? _c("i", { staticClass: "bx bxs-down-arrow" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.filter.order.toLowerCase() === "asc"
                          ? _c("i", { staticClass: "bx bxs-up-arrow" })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.withChart
                ? _c(
                    "div",
                    {
                      staticClass:
                        "watchlist-legend watchlist-legends__last-days",
                    },
                    [
                      _c("div", { staticClass: "watchlist-legends__name" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("filter.last_2_weeks")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "watchlist-legend watchlist-legends__settings",
              }),
            ]
          ),
          _vm._v(" "),
          !_vm.loading && _vm.projects.length > 0
            ? _c(
                "div",
                { class: { "watchlist-main__list": true } },
                _vm._l(_vm.projects, function (project, ind) {
                  return _c(
                    "div",
                    {
                      key: ind,
                      class: {
                        "watchlist-product": true,
                        "no-chart": !_vm.withChart,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: {
                            "watchlist-product__wrapper": true,
                            "no-chart": !_vm.withChart,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "watchlist-product__part watchlist-product__part_actions",
                            },
                            [
                              _vm.user !== null &&
                              _vm.watchlistChanging !== project.id
                                ? _c(
                                    "div",
                                    {
                                      class: {
                                        "watchlist-product__favorite watchlist-favorite": true,
                                        _favorite: _vm.insideWatchlist(
                                          project.id
                                        ),
                                      },
                                      attrs: {
                                        "text-tooltip": _vm.insideWatchlist(
                                          project.id
                                        )
                                          ? _vm.$t("project.remove")
                                          : _vm.$t("project.add"),
                                        flow: "right",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleWatchlist(project.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        class: {
                                          "bx bx-star watchlist-favorite__stroke": true,
                                          "bx bxs-star watchlist-favorite__filled":
                                            _vm.insideWatchlist(project.id),
                                        },
                                      }),
                                    ]
                                  )
                                : _vm.user !== null &&
                                  _vm.watchlistChanging === project.id
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "watchlist-product__favorite watchlist-favorite",
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bx bx-loader-alt loading watchlist-favorite__stroke",
                                      }),
                                    ]
                                  )
                                : _c(
                                    "nuxt-link",
                                    {
                                      staticClass:
                                        "watchlist-product__favorite watchlist-favorite",
                                      attrs: {
                                        to: _vm.localePath("/login"),
                                        "text-tooltip":
                                          _vm.$t("common.login_first"),
                                        flow: "right",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bx bx-star watchlist-favorite__stroke",
                                      }),
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "watchlist-product__num" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.filter.step *
                                          (_vm.filter.page - 1) +
                                          ind +
                                          1
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "watchlist-product__part watchlist-product-name",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "watchlist-product-name__box" },
                                [
                                  _c(
                                    "nuxt-link",
                                    {
                                      staticClass:
                                        "watchlist-product-name__icon",
                                      attrs: {
                                        to: _vm.$getProjectLink(
                                          project.type,
                                          project.slug
                                        ),
                                      },
                                    },
                                    [
                                      _c("nuxt-img", {
                                        attrs: {
                                          src: _vm.$getImage(
                                            project.project_image,
                                            "projects"
                                          ),
                                          alt:
                                            project.title +
                                            " " +
                                            _vm.$t("common.at") +
                                            " " +
                                            _vm.$config.appName,
                                          title: project.title,
                                          quality: 80,
                                          format: "webp",
                                          loading: "lazy",
                                        },
                                        on: {
                                          error: function ($event) {
                                            $event.target.src =
                                              _vm.$config.mediaUrl +
                                              "img/projects/default.webp"
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "nuxt-link",
                                    {
                                      staticClass:
                                        "watchlist-product-name__name",
                                      attrs: {
                                        to: _vm.$getProjectLink(
                                          project.type,
                                          project.slug
                                        ),
                                        title: project.title,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "watchlist-product-name__full-name",
                                        },
                                        [
                                          project.premium !== null
                                            ? _c("i", {
                                                staticClass:
                                                  "bx bxs-zap premium_icon",
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(project.title) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      project.token !== undefined &&
                                      project.token !== null &&
                                      project.token.length > 0 &&
                                      project.type !== "exchange"
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                  / " +
                                                _vm._s(
                                                  project.token.toUpperCase()
                                                ) +
                                                "\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "watchlist-product__part watchlist-product__price",
                            },
                            [
                              project.price !== null && project.price >= 0.0001
                                ? _c(
                                    "span",
                                    { staticClass: "watchlist-product__text" },
                                    [
                                      _vm._v(
                                        "\n              $" +
                                          _vm._s(
                                            project.price > 1
                                              ? _vm.$priceFormat(
                                                  project.price,
                                                  2
                                                )
                                              : _vm.$formatCryptoAmount(
                                                  project.price,
                                                  6
                                                )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : project.price !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "watchlist-product__text",
                                      attrs: {
                                        "text-tooltip":
                                          "$" +
                                          _vm.$formatCryptoAmount(
                                            project.price
                                          ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              $" +
                                          _vm._s(
                                            _vm.generateCutPrice(project.price)
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "watchlist-product__text" },
                                    [
                                      _vm._v(
                                        "\n              $...\n            "
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "watchlist-product__part watchlist-product-rate",
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "watchlist-product-rate__num": true,
                                    plus: project.daily_percent > 0,
                                    minus: project.daily_percent < 0,
                                  },
                                },
                                [
                                  project.daily_percent > 0
                                    ? _c("i", {
                                        staticClass: "bx bxs-up-arrow",
                                      })
                                    : project.daily_percent < 0
                                    ? _c("i", {
                                        staticClass: "bx bxs-down-arrow",
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        project.daily_percent !== null
                                          ? _vm.$priceFormat(
                                              project.daily_percent,
                                              2
                                            ) + "%"
                                          : "--"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "watchlist-product__part watchlist-product-rate",
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "watchlist-product-rate__num": true,
                                    plus: project.weekly_percent > 0,
                                    minus: project.weekly_percent < 0,
                                  },
                                },
                                [
                                  project.weekly_percent > 0
                                    ? _c("i", {
                                        staticClass: "bx bxs-up-arrow",
                                      })
                                    : project.weekly_percent < 0
                                    ? _c("i", {
                                        staticClass: "bx bxs-down-arrow",
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        project.weekly_percent !== null
                                          ? _vm.$priceFormat(
                                              project.weekly_percent,
                                              2
                                            ) + "%"
                                          : "--"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "watchlist-product__part watchlist-product__market-cap",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "watchlist-product__text" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        project.market_cap !== null
                                          ? "$" +
                                              _vm.$priceFormat(
                                                project.market_cap,
                                                0,
                                                ".",
                                                ","
                                              )
                                          : "--"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "watchlist-product__part watchlist-product-volume",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "watchlist-product-volume__text",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        project.daily !== null
                                          ? "$" +
                                              _vm.$priceFormat(project.daily, 0)
                                          : "--"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "watchlist-product__part watchlist-product-supply",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "watchlist-product__text" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        project.circulating_supply !== null
                                          ? "$" +
                                              _vm.$priceFormat(
                                                project.circulating_supply,
                                                0,
                                                ".",
                                                ","
                                              )
                                          : "--"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.withChart
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "watchlist-product__part watchlist-product__last-days",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "crypto-chart" },
                                    [
                                      _c(
                                        "client-only",
                                        [
                                          _c("MiniLineChart", {
                                            ref: "miniChart" + ind,
                                            refInFor: true,
                                            attrs: {
                                              "chart-data":
                                                _vm.generateChartData(
                                                  ind,
                                                  project.price_change
                                                ),
                                              options: _vm.chartOptions,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "watchlist-product__part watchlist-product-settings",
                            },
                            [
                              _c(
                                "nuxt-link",
                                {
                                  staticClass: "watchlist-product-more__button",
                                  attrs: {
                                    to: _vm.$getProjectLink(
                                      project.type,
                                      project.slug
                                    ),
                                    "aria-label": _vm.$t(
                                      "common.open_project_page"
                                    ),
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "bx bx-right-arrow-alt",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm.loading
            ? _c(
                "div",
                { staticClass: "watchlist-main__list" },
                _vm._l(20, function (ind) {
                  return _c(
                    "div",
                    { key: ind, staticClass: "watchlist-product" },
                    [
                      _c("div", { staticClass: "watchlist-product__wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "watchlist-product__part watchlist-product__part_actions",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "watchlist-product__num" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.filter.step * (_vm.filter.page - 1) +
                                        ind
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "watchlist-product__part watchlist-product-name",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "watchlist-product-name__box" },
                              [
                                _c("Skeleton", {
                                  staticClass: "watchlist-product-name__icon",
                                  attrs: {
                                    circle: "",
                                    width: "28px",
                                    height: "28px",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "watchlist-product-name__name",
                                  },
                                  [
                                    _c("Skeleton", {
                                      staticClass:
                                        "watchlist-product-name__full-name",
                                      attrs: { width: "50px", height: "16px" },
                                    }),
                                    _vm._v(
                                      "\n                /\n                "
                                    ),
                                    _c("Skeleton", {
                                      attrs: { width: "30px", height: "16px" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "watchlist-product__part watchlist-product__price",
                          },
                          [
                            _c("Skeleton", {
                              staticClass: "watchlist-product__text",
                              attrs: { width: "70px", height: "15px" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "watchlist-product__part watchlist-product-rate",
                          },
                          [
                            _c("Skeleton", {
                              staticClass: "watchlist-product__text",
                              attrs: { width: "40px", height: "15px" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "watchlist-product__part watchlist-product-rate",
                          },
                          [
                            _c("Skeleton", {
                              staticClass: "watchlist-product__text",
                              attrs: { width: "40px", height: "15px" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "watchlist-product__part watchlist-product__market-cap",
                          },
                          [
                            _c("Skeleton", {
                              staticClass: "watchlist-product__text",
                              attrs: { width: "100px", height: "15px" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "watchlist-product__part watchlist-product-volume",
                          },
                          [
                            _c("Skeleton", {
                              staticClass: "watchlist-product__text",
                              attrs: { width: "70px", height: "15px" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "watchlist-product__part watchlist-product-supply",
                          },
                          [
                            _c("Skeleton", {
                              staticClass: "watchlist-product__text",
                              attrs: { width: "70px", height: "15px" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.withChart
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "watchlist-product__part watchlist-product__last-days",
                              },
                              [
                                _c("Skeleton", {
                                  staticClass: "watchlist-product__text",
                                  attrs: { width: "160px", height: "50px" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._m(0, true),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _c(
                "div",
                { staticClass: "watchlist-main__list not-found-area" },
                [
                  _c("Empty"),
                  _vm._v(" "),
                  _c("div", { staticClass: "not-found-header" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("main.no_projects")) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "watchlist-product__part watchlist-product-settings" },
      [
        _c("div", { staticClass: "watchlist-product-more__button" }, [
          _c("i", { staticClass: "bx bx-right-arrow-alt" }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }